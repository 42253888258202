import React from 'react'
import PropTypes from 'prop-types'
import './style.scss'

class MobileMenu extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      active: 0,
      change: false,
      parentTitle: '',
      parentLink: '',
      parents: [],
      currentMenu: this.props.menuItems,
    }
    this.clickItem = this.clickItem.bind(this)
    this.clickBack = this.clickBack.bind(this)
  }

  getMenu(parents = this.state.parents) {
    const { menuItems } = this.props

    if (parents.length > 0) {
      let setMenu = menuItems
      let titles = []
      let links = []
      for (let i = 0; i < parents.length; i++) {
        titles = [
          ...titles,
          setMenu.find(el => el.post_id === parents[i]).title,
        ]
        links = [
          ...links,
          setMenu.find(el => el.post_id === parents[i]).permalink,
        ]
        setMenu = setMenu.find(el => el.post_id === parents[i]).items
      }
      this.setState({
        parentTitle: titles[titles.length - 1],
        parentLink: links[links.length - 1],
        currentMenu: setMenu,
      })
    } else {
      this.setState({
        parentTitle: '',
        parentLink: '',
        currentMenu: menuItems,
      })
    }
  }

  clickItem(item) {
    if ('items' in item) {
      const newParents = [...this.state.parents, item.post_id]
      this.setState({
        change: true,
      })
      setTimeout(
        function() {
          this.setState({
            active: item.post_id,
            parents: newParents,
            change: false,
          })
          this.getMenu(newParents)
        }.bind(this),
        500
      )
    } else {
      window.location.href = item.permalink
    }
  }

  clickBack() {
    const setParents = this.state.parents.slice(0, -1)
    this.setState({
      change: true,
    })
    setTimeout(
      function() {
        this.setState({
          active: setParents[setParents.length - 1],
          parents: setParents,
          change: false,
        })
        this.getMenu(setParents)
      }.bind(this),
      500
    )
  }

  render() {
    const { active, parentTitle, parentLink, currentMenu, change } = this.state
    const { activeMenu, globals } = this.props
    return (
      <div
        className={'dhsv-mobile-menu' + activeMenu + (change ? ' changed' : '')}
        data-active={active}
      >
        {currentMenu[0].menu_item_parent !== 0 ? (
          <div onClick={this.clickBack} className="title">
            <button>
              <i className="icon ion-ios-arrow-thin-left" />
            </button>
            <span>Zurück</span>
          </div>
        ) : null}
        <div className="brand">
          <a href={globals.logo_link}>
            <img src={globals.logo_url} />
          </a>
        </div>
        <ul className={'menu'}>
          {currentMenu[0].menu_item_parent !== 0 ? (
            <li className="overview">
              <a href={parentLink}>{parentTitle}</a>
            </li>
          ) : null}
          {currentMenu.map((menuItem, index) => (
            <li
              key={index}
              className={menuItem.items ? 'has-children' : null}
              onClick={() => this.clickItem(menuItem)}
              style={{
                animationDelay: 0.1 + index * 0.1 + 's',
              }}
            >
              {menuItem.title ? menuItem.title : null}
            </li>
          ))}
        </ul>
        <ul className="meta-menu">
          <li>
            <a href="/reservieren">Reservieren</a>
          </li>
          <li>
            <a href="/bestellen">Bestellen</a>
          </li>
          <li>
            <a href="/jobs">Bewerben</a>
          </li>
        </ul>
        <ul className="icon-menu">
          <li>
            <a
              href="https://www.facebook.com/UmstaedterBrauhaus/"
              rel="noopener noreferrer"
              target="_blank"
            >
              <i className="ion-social-facebook" />
            </a>
          </li>
          <li>
            <a
              href="https://www.instagram.com/umstaedter_brauhaus/"
              rel="noopener noreferrer"
              target="_blank"
            >
              <i className="ion-social-instagram" />
            </a>
          </li>
          <li>
            <a href="tel:+4960783345" rel="noopener noreferrer" target="_blank">
              <i className="ion-android-call" />
            </a>
          </li>
          <li>
            <a
              href="https://goo.gl/maps/dcHCATXwRqhkZt3h9"
              rel="noopener noreferrer"
              target="_blank"
            >
              <i className="ion-android-pin" />
            </a>
          </li>
        </ul>
      </div>
    )
  }
}

MobileMenu.propTypes = {
  isMobile: PropTypes.bool,
  menuItems: PropTypes.array,
  activeMenu: PropTypes.string,
  globals: PropTypes.object,
}

export default MobileMenu
