import $ from 'jquery'

$(document).ready(dhsv_vc_beer_section)
$(window).resize(dhsv_vc_beer_section)

function dhsv_vc_beer_section() {
  const winW = $(window).width()
  $('.dhsv_vc_beer_section').each(function() {
    const $image = $(this).find('.image')
    const url =
      winW > 768 ? $image.attr('data-desktop') : $image.attr('data-mobile')

    $image.css({
      backgroundImage: 'url(' + url + ')',
    })
  })
}
