// import external dependencies
import 'jquery'

// import everything from autoload
import './autoload/_bootstrap.js' // eslint-disable-line

// import vc-elements javascript files
import '../vc_elements/_new/main.js'; import '../vc_elements/beer_section/main.js'; import '../vc_elements/button/main.js'; import '../vc_elements/content_section/main.js'; import '../vc_elements/offnungszeiten/main.js'; import '../vc_elements/stage/main.js' // eslint-disable-line

// import react habitat and react components
import './init/react'

// import local dependencies
import Router from './util/Router'
import common from './routes/common'
import home from './routes/home'
import aboutUs from './routes/about'

/** Populate Router instance with DOM routes */
const routes = new Router({
  // All pages
  common,
  // Home page
  home,
  // About Us page, note the change from about-us to aboutUs.
  aboutUs,
})

// Load Events
jQuery(document).ready(() => routes.loadEvents())
