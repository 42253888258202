export default {
  init() {
    $(window).load(function() {
      scrollDownButton()
      activeAnimation()
      close_banner()
    })

    $(window).scroll(activeAnimation)

    $('.vc_row')
      .last()
      .addClass('last-row')

    const appHeight = () => {
      const doc = document.documentElement
      doc.style.setProperty('--app-height', `${window.innerHeight}px`)
    }
    window.addEventListener('resize', appHeight)
    appHeight()
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
}

function close_banner() {
  $('.info_box_container_wrapper i').on('click tap', function() {
    $('.info_box_container_wrapper').css('display', 'none')
    $('.overlay').css('display', 'none')
  })
}

function scrollDownButton() {
  $('.scrolldown').each(function() {
    const $button = $(this)
    const $row = $button.parents('.vc_row')
    const $nextRow = $row.next()
    const parentBg = $button.parent().attr('data-bg')

    $button.addClass(parentBg)

    if ($row.hasClass('last-row')) {
      $button.addClass('last-button')
    }

    $button.click(function() {
      $('body, html').animate(
        {
          scrollTop: $row.hasClass('last-row') ? 0 : $nextRow.offset().top,
        },
        1000
      )
    })
  })
}

function activeAnimation() {
  const winST = $(window).scrollTop()
  const winH = $(window).height()
  $('.scrollsection').each(function() {
    const elTop = $(this).offset().top
    if (winST + winH / 2 >= elTop) {
      $(this).addClass('active-scroll')
    } else {
      $(this).removeClass('active-scroll')
    }
  })
}
