import $ from 'jquery'
import 'slick-carousel'

$(document).ready(function() {
  $('.dhsv_vc_content_section').each(function() {
    const $el = $(this)
    const $content = $el.find('.content')
    const $inner = $el.find('.inner')
    const iHeight = $inner.height()
    const $more = $content.find('.show_more')

    $(this)
      .find('.images')
      .slick({
        dots: true,
      })

    $more.click(function() {
      if ($(this).hasClass('extand')) {
        $content.css('max-height', '')
        $(this).removeClass('extand')
      } else {
        $content.css('height', iHeight + 50)
        $content.css('max-height', iHeight + 50)
        $(this).addClass('extand')
      }
    })
  })
})

$(window).load(dhsv_vc_content_section)
$(window).resize(dhsv_vc_content_section)
$(window).scroll(dhsv_vc_content_section)

function dhsv_vc_content_section() {
  $('.dhsv_vc_content_section').each(function() {
    const $el = $(this)
    const $content = $el.find('.content')
    const cHeight = $content.height()
    const $inner = $el.find('.inner')
    const iHeight = $inner.height()
    $content.attr('data-open-height', iHeight)
    if (cHeight < iHeight) {
      $content.find('.show_more').addClass('active')
    }

    $el.find('.images').each(function() {
      const $image = $(this).find('.image')
      $image.height(0)
      $image.height($(this).height())
    })
  })
}
