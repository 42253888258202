import React from 'react'
import PropTypes from 'prop-types'
import MobileMenu from './../MobileMenu'
import './style.scss'

class MobileHeader extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      sticky: false,
      toggled: false,
      showMenu: false,
    }
    this.handleClick = this.handleClick.bind(this)
    this.stickyHeader = this.stickyHeader.bind(this)
  }

  componentDidMount() {
    window.addEventListener('scroll', this.stickyHeader)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.stickyHeader)
  }

  stickyHeader() {
    this.setState({
      sticky: window.pageYOffset > 100,
    })
  }

  handleClick() {
    const { toggled, showMenu } = this.state
    if (toggled) {
      this.setState({
        toggled: !toggled,
      })
      setTimeout(
        function() {
          this.setState({
            showMenu: !showMenu,
          })
        }.bind(this),
        500
      )
    } else {
      this.setState({
        showMenu: !showMenu,
      })
      setTimeout(
        function() {
          this.setState({
            toggled: !toggled,
          })
        }.bind(this),
        0
      )
    }
  }

  render() {
    const { menuItems, globals, isMobile } = this.props
    const { toggled, showMenu, sticky } = this.state
    return (
      <div className={'dhsv-mobile-header'}>
        <div className={'header' + (sticky && !toggled ? ' sticky' : '')}>
          {!isMobile ? (
            <div className="infos">
              <a
                href="tel:+4960783345"
                rel="noopener noreferrer"
                target="_blank"
              >
                <i className="ion-android-call" /> 06078 3345
              </a>
              <a
                href="https://goo.gl/maps/dcHCATXwRqhkZt3h9"
                rel="noopener noreferrer"
                target="_blank"
              >
                <i className="ion-android-pin" /> Anfahrt
              </a>
            </div>
          ) : null}
          <div className="brand">
            <a href={globals.logo_link}>
              <img src={globals.logo_sticky_url} />
            </a>
          </div>
          <div
            className={'toggle' + (toggled ? ' open-toggle' : '')}
            onClick={this.handleClick}
          >
            <span />
            <span />
            <span />
          </div>
        </div>
        {showMenu ? (
          <MobileMenu
            globals={globals}
            menuItems={menuItems}
            activeMenu={toggled ? ' open-menu' : ''}
          />
        ) : null}
      </div>
    )
  }
}

MobileHeader.propTypes = {
  isMobile: PropTypes.bool,
  globals: PropTypes.object,
  menuItems: PropTypes.array,
}

export default MobileHeader
